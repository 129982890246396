/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
var $init_une_container_height = 0, $home_nav = null;

var $une_container = null,
  $main_une = null,
  $stickyNavbar = null,
  $homepageNavbar = null,
  $adminbar = null,
  haveAdminBar = false,
  menuTopValue = "0",
  initHeight = 0,
  fullHeaderHeight = 0,
  stickyHeaderHeight = 0,
  homePageWaitBeforeApplyHeightInterval = 0;

var bs_mq_xs = 480, bs_mq_sm = 768, bs_mq_md = 992, bs_mq_lg = 1200, bLazy;


(function($) {


  function setSizesForLeftSidebar()
  {
    $home_nav = $("#homepage_navbar");
    $une_container = $(".last-numero");
    $main_une = $(".last-numero .une");
    $stickyNavbar = $('#sticky_navbar');
    $homepageNavbar = $('#homepage_navbar');
    $adminbar = $('#wpadminbar');
    haveAdminBar = ($adminbar.height() !== undefined);
    menuTopValue = haveAdminBar ? $adminbar.height() : "0";

    $init_une_container_height = $une_container.height();

    fullHeaderHeight = haveAdminBar ? $homepageNavbar.height() + $adminbar.height() : $homepageNavbar.height();
    stickyHeaderHeight = haveAdminBar ? $stickyNavbar.height() + $adminbar.height() : $stickyNavbar.height();
    //targetheight = $init_une_container_height - fullHeaderHeight;
    initHeight = $("body").height() - fullHeaderHeight;
  }

  function applyHeightToLeftSidebar(h) {
    if (Modernizr.mq('(min-width: '+bs_mq_md+'px)'))
    {
      $une_container.height(h);
      $main_une.height(h);
    }
  }


  function setHomePageSidebarHeight() {
    setSizesForLeftSidebar();
    applyHeightToLeftSidebar($("body").height() - fullHeaderHeight);
    clearInterval(homePageWaitBeforeApplyHeightInterval);
  }


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        // JavaScript to be fired on all pages
        $('img[src$=".svg"]').each(function(){
          var $img = $(this),
              imgID = $img.attr('id'),
              imgClass = $img.attr('class'),
              imgURL = $img.attr('src');

          $.get(imgURL, function(data) {
              // Get the SVG tag, ignore the rest
              var $svg = $(data).find('svg');

              // Add replaced image's ID to the new SVG
              if(typeof imgID !== 'undefined') {
                  $svg = $svg.attr('id', imgID);
              }
              // Add replaced image's classes to the new SVG
              if(typeof imgClass !== 'undefined') {
                  $svg = $svg.attr('class', imgClass+' replaced-svg');
              }

              // Remove any invalid XML tags as per http://validator.w3.org
              $svg = $svg.removeAttr('xmlns:a');

              // Replace image with new SVG
              $img.replaceWith($svg);
          }, 'xml');
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $('.devenez-membre-cta').append("<div class='actions'><a href='devenez-membre' class='btn-circle cta-default'><i></i></a></div>");
        $('.devenez-membre-cta').prepend("<a href='devenez-membre' title='Devenez membre' target='_blanc'>&nbsp;</a>");


        $("#mm-primary-menu").mmenu({
          "extensions": [
            "theme-dark",
            "effect-listitems-slide",
            "effect-menu-slide",
            "pagedim-black",
            "pageshadow"
         ],
         "navbar": {
              "add": true,
              "title": "Journal le Trait d'union du Nord"
          },
          "navbars": [
          {
            "position":"bottom",
            "content": [
              "<a class='fa fa-envelope' href='#/'></a>",
              "<a class='fa fa-twitter' href='#/'></a>",
              "<a class='fa fa-facebook' href='#/'></a>"
            ]
          }]
        },
        //configuration
        {
          "offCanvas": {
            "position": "left",
            "zposition": "front",
            "pageNodetype": "main"
          }
        });
        $("#mm-primary-menu").removeClass("hidden-xs");
        $("#mm-primary-menu").removeClass("hidden-sm");
        $("#mm-primary-menu").removeClass("hidden-md");
        $("#mm-primary-menu").removeClass("hidden-lg");

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function()
      {

        setSizesForLeftSidebar();

        $homepagenavwp = $("#site").waypoint({
          handler: function(direction)
          {
            var $target = $('#sticky_navbar');

            if (direction === "down")
            {
              $target.removeClass("hide");
              $target.addClass("show");
              $target.animate({
                top: menuTopValue,
                easing: "ease-in-out"
                }, 300, function() {
                  applyHeightToLeftSidebar($("body").height() - stickyHeaderHeight);
              });
            }
            else if (direction === "up")
            {
              $target.animate({
                top: "-90",
                easing: "ease-in-out"
                }, 150, function() {
                  applyHeightToLeftSidebar($("body").height() - fullHeaderHeight);
              });
            }
          },
          offset: '1%'
        });

        var bLazy = new Blazy({ container: '#main_container'});

        if ($('body').hasClass('home'))
        {
          applyHeightToLeftSidebar($("body").height() - fullHeaderHeight);
        }

        homePageWaitBeforeApplyHeightInterval = setInterval(setHomePageSidebarHeight, 1000);

        $(window).resize(function ()
        {
          setSizesForLeftSidebar();
          applyHeightToLeftSidebar($("body").height() - fullHeaderHeight);
        });
      }
    },
    // page
    'page': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {

        setSizesForLeftSidebar();

        //fixed the height o
        var $right_sidebar = $(".content-sidebar-right");

        if ($('body').hasClass('home'))
        {
          applyHeightToLeftSidebar($("body").height() - fullHeaderHeight);
        }
        else
        {
          applyHeightToLeftSidebar($("body").height() - stickyHeaderHeight);
        }

        $(window).resize(function () {
          if ( $('body').hasClass('home'))
          {
            applyHeightToLeftSidebar($("body").height() - fullHeaderHeight);
          }
          else
          {
            applyHeightToLeftSidebar($("body").height() - stickyHeaderHeight);
          }
        });

        // JavaScript to be fired on the home page, after the init JS
        $footerWaypoint = $("#main_footer").waypoint({
          handler: function(direction)
          {
            var $target = $(".last-numero"), $main_une = $(".last-numero .une"),
            $current_une_container_height = $target.height();

            if (direction === "down")
            {
              $target.removeClass("sticky");
              $target.addClass("not-sticky");

            }
            else if (direction === "up")
            {
              $target.removeClass("not-sticky");
              $target.addClass("sticky");

            }
          },
          offset: '100%'
        });


        $pageHeaderWaypoint = $(".content").waypoint({
          handler: function(direction)
          {
            var $target = $('.current-news > header > .page-header');
            if (direction === "down")
            {
              $target.fadeOut();
            }
            else if (direction === "up")
            {
              $target.fadeIn();
            }
          },
          offset: '30%'
        });

      }
    },
    // Publications
    'post_type_archive_publications': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {

        //fixed the height o
        var $left_sticky_sidebar = $(".nav-news"),
            $left_sticky_sidebar_height = $left_sticky_sidebar.height();
        var $right_sidebar = $(".content-sidebar-right");

        var bLazy = new Blazy({ container: '#navnews_bigscreen'});

        $left_sticky_sidebar.height($left_sticky_sidebar_height);

        $(window).resize(function () {
          $left_sticky_sidebar_height = $left_sticky_sidebar.height();
          $left_sticky_sidebar.height($left_sticky_sidebar_height);
        });

        if (Modernizr.mq('(min-width: '+bs_mq_lg+'px)'))
        {
          // JavaScript to be fired on the home page, after the init JS
          $footerWaypoint = $("#main_footer").waypoint({
            handler: function(direction)
            {
              $target = $('.nav-news');
              if (direction === "down")
              {
                $target.removeClass("sticky");
                $target.addClass("not-sticky");
                $left_sticky_sidebar.height($left_sticky_sidebar_height);
              }
              else if (direction === "up")
              {
                $target.removeClass("not-sticky");
                $target.addClass("sticky");
              }
            },
            offset: '100%'
          });
        }
        $pageHeaderWaypoint = $(".content").waypoint({
          handler: function(direction)
          {
            var $target = $('.page-header');
            if (direction === "down")
            {
              $target.fadeOut();
            }
            else if (direction === "up")
            {
              $target.fadeIn();
            }
          },
          offset: '30%'
        });
      }
    },
    'archive': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {

        $("#mobile_publication_nav").mmenu({
          "extensions": [
            "theme-dark",
            "effect-listitems-slide",
            "effect-menu-slide",
            "pagedim-black",
            "pageshadow"
         ]
        },
        //configuration
        {
          "offCanvas": {
            "position": "left",
            "zposition": "front",
            "pageNodetype": "main"
          }
        });
      }
    },
    // Publications single
    'single_publications': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {

        //fixed the height o
        var $left_sticky_sidebar = $(".nav-news"),
            $left_sticky_sidebar_height = $left_sticky_sidebar.height();
        var $right_sidebar = $(".content-sidebar-right");

        $left_sticky_sidebar.height($left_sticky_sidebar_height);

        var bLazy = new Blazy({ container: '#navnews_bigscreen'});

        $(window).resize(function () {
          $left_sticky_sidebar_height = $left_sticky_sidebar.height();
          $left_sticky_sidebar.height($left_sticky_sidebar_height);
        });

        if (Modernizr.mq('(min-width: '+bs_mq_lg+'px)'))
        {
          // JavaScript to be fired on the home page, after the init JS
          $footerWaypoint = $("#main_footer").waypoint({
            handler: function(direction)
            {
              $target = $('.nav-news');
              if (direction === "down")
              {
                $target.removeClass("sticky");
                $target.addClass("not-sticky");
              }
              else if (direction === "up")
              {
                $target.removeClass("not-sticky");
                $target.addClass("sticky");
              }
            },
            offset: '100%'
          });
        }

        $pageHeaderWaypoint = $(".content").waypoint({
          handler: function(direction)
          {
            var $target = $('.page-header');
            if (direction === "down")
            {
              $target.fadeOut();
            }
            else if (direction === "up")
            {
              $target.fadeIn();
            }
          },
          offset: '40%'
        });

        $("#mobile_publication_nav").mmenu({
          "extensions": [
            "theme-dark",
            "effect-listitems-slide",
            "effect-menu-slide",
            "pagedim-black",
            "pageshadow"
          ]
        },
        //configuration
        {
          "offCanvas": {
            "position": "left",
            "zposition": "front",
            "pageNodetype": "main"
          }
        });

        var bLazy = new Blazy({ container: '#navnews_bigscreen'});
      }
    },
    // Publications taxonmie motcles
    'tax_motscles': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {

        //fixed the height o
        var $left_sticky_sidebar = $(".nav-news"),
            $left_sticky_sidebar_height = $left_sticky_sidebar.height();
        var $right_sidebar = $(".content-sidebar-right");

        $left_sticky_sidebar.height($left_sticky_sidebar_height);

        var bLazy = new Blazy({ container: '#navnews_bigscreen'});

        $(window).resize(function () {
          $left_sticky_sidebar_height = $left_sticky_sidebar.height();
          $left_sticky_sidebar.height($left_sticky_sidebar_height);
        });

        if (Modernizr.mq('(min-width: '+bs_mq_lg+'px)'))
        {
          // JavaScript to be fired on the home page, after the init JS
          $footerWaypoint = $("#main_footer").waypoint({
            handler: function(direction)
            {
              $target = $('.nav-news');
              if (direction === "down")
              {
                $target.removeClass("sticky");
                $target.addClass("not-sticky");
              }
              else if (direction === "up")
              {
                $target.removeClass("not-sticky");
                $target.addClass("sticky");
              }
            },
            offset: '100%'
          });
        }
      }
    },
    // Publications taxonmie volumes
    'tax_volume': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {

        //fixed the height o
        var $left_sticky_sidebar = $(".nav-news"),
            $left_sticky_sidebar_height = $left_sticky_sidebar.height();

        $left_sticky_sidebar.height($left_sticky_sidebar_height);

        var bLazy = new Blazy({ container: '#navnews_bigscreen'});

        $(window).resize(function () {
          $left_sticky_sidebar_height = $left_sticky_sidebar.height();
          $left_sticky_sidebar.height($left_sticky_sidebar_height);
        });

        if (Modernizr.mq('(min-width: '+bs_mq_lg+'px)'))
        {
          // JavaScript to be fired on the home page, after the init JS
          $footerWaypoint = $("#main_footer").waypoint({
            handler: function(direction)
            {
              $target = $('.nav-news');
              if (direction === "down")
              {
                $target.removeClass("sticky");
                $target.addClass("not-sticky");
              }
              else if (direction === "up")
              {
                $target.removeClass("not-sticky");
                $target.addClass("sticky");
              }
            },
            offset: '100%'
          });
        }
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
